<template>
  <html lang="en" class="notranslate" translate="no"><!-- All translators -->
  <head>
    <meta name="google" content="notranslate"/> <!-- Just for google -->
  </head>
  <div class="app">
    <Loading :isLoading="isLoading"/>
    <Menu :list="menuList"/>
    <body>
    <div class="mx-auto">
      <div class="row">
        <div class="flex-1 bg-gray-1 px-0 py-0 m-0 ">
          <div class="flex bg-gray-000  grid grid-cols-1 lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-2;">
            <div class="collapse dont-collapse-sm">
              <img src="@/assets/login.jpg" class="img-responsive min-h-full" loading="lazy">
            </div>
            <div class="center mx-2 sm:mx-20">
              <div class="col-xs-10 py-16">
                <div>
                  <p class="title pt-4 ml-5 text-left">Iniciar sesión</p>
                  <p class="ml-5 text-left RobotoRegular text-lg" style="color:#726659">
                    Ingrese sus datos para acceder a su cuenta
                  </p>
                </div>
                <div>
                  <p v-if="errors.length">
                  <ul>
                    <li><h5 class="RobotoRegular" style="color:red">{{ errors }}</h5></li>
                  </ul>
                  </p>
                </div>
                <form @submit="loginUser" class="px-5 pt-8 mb-4 text-left">

                  <div class="form-group" style="margin-top:-5px;">
                    <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a;">Correo Electrónico</p>
                    <input type="email"
                           class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="Input_EmaiUser_Login" minlength="10"
                           v-model="email" required>
                  </div>
                  <br>
                  <div class="form-group" style="margin-top:-15px;">
                    <p class="text-left mt-3 mb-1 RobotoLight text-base" style="color: #8e857a;">Contraseña</p>
                    <input type="password" 
                      v-model="password" class="RobotoRegular shadow appearance-none border rounded w-full py-2.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="input_password" />
                    <button v-if="isEyeClosed" type="button" v-on:click="changeEyeButton()" id="copyTextButton" class="eyeButton" value=""></button>
                    <button v-if="!isEyeClosed" type="button" v-on:click="changeEyeButton()" id="copyTextButton" class="eyeClosedButton" value=""></button>
                  </div>

                  <div>
                    <div class="text-center">
                      <p style="color: rgb(146, 132, 128);" class="RobotoRegular mt-5">¿Olvidaste tu contraseña?</p>
                      <router-link class="RobotoBold text-base mt-4" style="color:#726659;"
                         to="/recuperar-contrasena">
                        ¡Recuperala aquí!
                      </router-link>
                    </div>
                  </div>

                  <br>
                  <div>
                    <button class="btn btn-primary btn-lg" id="Button_Access_LogIn"  type="submit">
                      Iniciar Sesión
                    </button>
                  </div>
                </form>
                
                <div class="mt-16">
                  <div>
                    <p class="RobotoRegular text-base text-center" style="color: #726659;">¿No tienes una cuenta? <router-link class="RobotoBold" to="/registro" style="color: #726659;"><span style="font-weight:500;">¡Créala ahora!</span></router-link></p>
                  </div>
                </div>

                <div class="RobotoRegular text-left mt-4 mb-4 px-5" style="color:#726659">
                  <span class="RobotoBold">AVISO</span>: FONCABSA no opera con el navegador <span style="font-weight:500;">Microsoft Edge</span>.
                  Copie la siguiente url en Google Chrome para poder acceder a tu cuenta:

                  <br>
                  <br>

                  <a class="text-blue-600 hover:text-blue-700 hover:underline" href="https://foncabsa.com/login" target="blanck">https://foncabsa.com/login</a>
                  
                  <br>
                  <br>
                  
                  ¿No tiene Google Chrome? Descárguelo en el siguiente enlace:

                  <br>
                  <br>

                  <a href="https://cutt.ly/3j6xmbA" class="text-blue-600 hover:text-blue-700 hover:underline" target="blanck">https://cutt.ly/3j6xmbA</a>
                </div>
                <br>
              </div>
              <div class="col-xs-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </body>
    <Footer3/>
  </div>

  </html>
</template>


<script>
import { ref, onBeforeMount } from 'vue';
import('@/assets/css/stylesheet.css')

import Menu from '../components/Menu.vue';
import Footer3 from '../components/Footer3.vue';
import Swal from 'sweetalert2';
import {getTokenDecoden} from "@/helpers/tokenauth";
import Loading from "@/components/Loading/Loadingc.vue"

import menuOptions  from "@/helpers/menu.js";

export default {
  name: 'LoginComponent',
  setup() {
    const menuList = ref([]);
    const errors = ref([]);
    const isLoading = ref(true);
    const apiToken = ref("");
    const email = ref("");
    const password = ref("");
    const isEyeClosed = ref(false);
    const ccapi = ref(process.env.VUE_APP_CCAPI);

    onBeforeMount(async () => {
        await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
          menuList.value = resp.data.FONCABSA;
      }).catch(err => {
          console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList,
      errors,
      isLoading,
      apiToken,
      email,
      password,
      isEyeClosed,
      ccapi
    };
  },
  components: {
    Menu,
    Footer3,
    Loading
  },
  async mounted() {
    await this.activateAccount();
  },
  beforeMount() {
    setTimeout(() => {
      console.log("🚀 ~ file: Login.vue:184 ~ setTimeout ~ this.isLoading", this.isLoading);
      this.isLoading=false;
      console.log("🚀 ~ file: Login.vue:186 ~ setTimeout ~ this.isLoading", this.isLoading);
    }, 500); 
  },
  methods: {
    changeEyeButton() {
      var input_password = document.getElementById("input_password");
      if (input_password.type === "password") {
        input_password.type = "text";
      } else {
        input_password.type = "password";
      }
      this.isEyeClosed = !this.isEyeClosed;
    },
    async activateAccount() {
      let confirmationToken = this.getParameterByName('reference');
      let confirmationEmail = this.getParameterByName('email');

      if (confirmationToken != "" && confirmationEmail != "") {
        this.isLoading = true;
        await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/confirmation?confirmation_token=` + confirmationToken + "&email=" + confirmationEmail, {
        }).then(() => {
          Swal.fire({
            title: "Aviso",
            text: "Se activó su cuenta correctamente!",
            icon: "success",
            confirmButtonColor: '#FEB72B',
          });
        }).catch(error => {
          console.log("Errores de activacion: ",error.response.data.errores);
          if(error.response.data.errores.includes("Este usuario ya ha sido activado.")){
            Swal.fire({
              title: "Aviso",
              text: "La cuenta ya esta verificada.",
              icon: "info",
              confirmButtonColor: '#FEB72B',
            });
          }else{
            Swal.fire({
              title: "Aviso",
              text: "Ocurrió un error al confirmar la cuenta. Intentelo más tarde.",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
          }
        }).finally(() => (this.isLoading = false));
      }
    },
    getParameterByName(name) {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href);
      if (results == null)
        return "";
      else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    async loginUser(e) {
      e.preventDefault();
      var browser = (function (agent) {
        switch (true) {
          case agent.indexOf("edge") > -1:
            return "edge";
          case agent.indexOf("edg") > -1:
            return "chromium based edge (dev or canary)";
          case agent.indexOf("opr") > -1 && !!window.opr:
            return "opera";
          case agent.indexOf("chrome") > -1 && !!window.chrome:
            return "chrome";
          case agent.indexOf("trident") > -1:
            return "ie";
          case agent.indexOf("firefox") > -1:
            return "firefox";
          case agent.indexOf("safari") > -1:
            return "safari";
          default:
            return "other";
        }
      })(window.navigator.userAgent.toLowerCase());

      if (browser != "chrome" && browser != "safari") {
        Swal.fire({
          title: "Aviso",
          text: "Para mejorar la experiencia de uso de ésta plataforma, le recomendamos usar los navegadores Chrome o Safari.",
          icon: "info",
          confirmButtonColor: '#FEB72B',
        })
        return
      }

      this.isLoading = true;
      this.errors = [];
      let data = {
        user: {
          email: this.email.toLowerCase(),
          password: this.password
        }
      };

      await this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/login/`, data,
          {
            'Content-Type': 'application/json'
          })
          .then(response => {
            // let auth = {
            //   "processed": response.data.procesado,
            //   "message": response.data.mensaje,
            //   "token": response.data.token_auth,
            //   "id": response.data.obj.id,
            //   "email": response.data.obj.email,
            //   "created_at": response.data.obj.created_at,
            //   "updated_at": response.data.obj.updated_at,
            //   "rfc": response.data.obj.person != undefined ? response.data.obj.person.rfc : "",
            //   "paternal_name": response.data.obj.person != undefined ? response.data.obj.person.paternal_name : "",
            //   "maternal_name": response.data.obj.person != undefined ? response.data.obj.person.maternal_name : "",
            //   "middle_name": response.data.obj.person != undefined ? response.data.obj.person.middle_name : "",
            //   "name": response.data.obj.person != undefined ? response.data.obj.person.name : "",
            //   "associate_id": response.data.associate != undefined ? response.data.associate.id : "",
            //   "name_associate": response.data.associate != undefined ? response.data.associate.name : "",
            //   "tipo_cuenta": response.data.obj.role,
            //   "person_id": response.data.obj.person != undefined ? response.data.obj.person.id : "",
            //   "referal_code": response.data.obj.referal_code
            // };
            // window.sessionStorage.setItem('login', JSON.stringify(auth));
            window.sessionStorage.setItem('login',response.data.token_auth);
            //se fija tipo de cuenta como cliente porque no se tiene el proceso de admin
            this.checkLogin();
            // this.$router.push({name: "PrincipalCuenta"});
          })
          .catch((error) => {
            console.log("Errpr",  error);
            // console.log("Errpr",  error.response.data.mensaje);
            //this.errors = error.response.data.mensaje;
            Swal.fire({
                    title: "Aviso",
                    html: `<span style="font-weight: bold; color:red">${error.response.data.mensaje}</span>`,
                    icon: "error",
                    confirmButtonColor: '#FEB72B',
                  });
            //this.transformMessages();
          }).finally(() => (this.isLoading = false));
    },
    checkLogin() {
      var user =getTokenDecoden();
      if (user.obj['role'] != null) {
        switch (user.obj['role']) {
          case "member":
          case "cabsa_member":
            this.redirectTo();
            break;
          default:
            Swal.fire({
              title: "Aviso",
              html: `<span style="font-weight: bold; color:red">La cuenta con la que desea entrar es para administradores.<br><br>Será redireccionado a la página correcta.</span>`,
              confirmButtonColor: '#FEB72B',
              confirmButtonText: 'Redireccionar',
            }).then(() => {
              sessionStorage.clear();
              // window.location.href = "/personal/login"
              this.$router.push({path:"/personal/login"});
            })
        }
      }
    },
    transformMessages() {
      var lista_errores = []
      this.errors.forEach(function (element) {
        if (element == "cuenta dada de baja") {
          lista_errores.push("La cuenta esta dada de baja")
        }
        if (element == "cuenta inactiva") {
          lista_errores.push("La cuenta esta inactiva")
        }
        if (element == "cuenta pendiente de activacion") {
          lista_errores.push("Cuenta pendiente de activacion")
        }
        //if(element == "usuario no registrado"){
        //  lista_errores.push("Usuario o contraseña incorrectos")
        //}
        if (element == "contrasña incorrecta") {
          lista_errores.push("Usuario o contraseña incorrectos")
        } else {
          if (element != "cuenta pendiente de activacion") {
            lista_errores.push(element)
          }
        }
      });
      this.errors = lista_errores;
    },
    redirectTo(){
      var path = this.getParameterByName("redirect");
      if(path != ""){
        this.$router.push({path:path});
      }else{
        this.$router.push({path: "/cuenta/principal"});

      }
    }
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

/* @media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
} */
@media only screen and (max-width: 1000px) {
  .collapse  {
    display: none;
    /* height: auto !important;
    visibility: visible; */
  }
}

#Button_Access_LogIn {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #FFCC00;
  border: 1px solid #FFCC00;
  font-family: Roboto;
  font-weight: 400;
  width: 100%;
  height: 50px;
  color: white;
  transition: all 300ms;
  width: 100%;
  /* style="height:50px;width:100%; background-color:#fdce00" */
}

#Button_Access_LogIn:hover {
  background-color: #e2b603;
  border: 1px solid #e2b603;
}

button {
  outline: none !important;
}

.eyeButton{ 
  background-image: url("../assets/Cuenta/eye.png") !important;
  background-position: center !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
}

.eyeButton[type="button"] {
    position: absolute;
    margin-left: -50px;
    height: 40px;
    width: 50px;
    margin-top: 5px;
    /* background: #fff; */
    color: white;
    /* border: 1px solid #810042; */
    border-left: 0px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    -webkit-appearance: none;
}

.eyeClosedButton{ 
  background-image: url("../assets/Cuenta/eye_closed.png") !important;
  background-position: center !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
}

.eyeClosedButton[type="button"] {
    position: absolute;
    margin-left: -50px;
    height: 40px;
    width: 50px;
    margin-top: 5px;
    /* background: #fff; */
    color: white;
    /* border: 1px solid #810042; */
    border-left: 0px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    -webkit-appearance: none;
}

#Input_EmaiUser_Login:focus-visible,
#input_password:focus-visible {
  border: 2px solid #810042 ;
  outline: none;
}
</style>